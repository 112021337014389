<template>
	<div class="tcontainer">
		<div class="clearfix">
			<div style="margin: 10px; float: left">
				<Calendar @hide="loadLazyData" placeholder="Select Range" v-model="dateRange" selectionMode="range" dateFormat="dd/mm/yy" />
			</div>
			<div style="margin: 10px; float: right">
				<button type="button" class="btn btn-primary" @click="downloadReport"> <i class="fa fa-file-csv"></i> </button>
			</div>
		</div>

		<div class="clearfix ttitle">
			<div class="pull-right" style="margin: 5px;">
				Total Reports: {{ totalReports }}
				<div v-if="isSuper">
					<div>Test Price: &#8377;{{ testPrice }}</div>
					<div>Doctor Fees: &#8377;{{ doctorPrice }}</div>
					<div>Agent Commission: &#8377;{{ agentPrice }}</div>
					<div>Profit: &#8377;{{ testPrice - doctorPrice - agentPrice }}</div>
				</div>
			</div>
			<div class="title">PFT Uploading</div>
		</div>
		<table class="table table-bordered table-striped">
			<thead>
				<tr>
					<th>Id</th>
					<th>Center Name</th>
					<th>Contact Person</th>
					<th>Phone</th>
					<th>Report Count</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="row in centers" :key="row">
					<td>{{ row.id }}</td>
					<td>{{ row.name }}</td>
					<td>{{ row.contact_person }}</td>
					<td>{{ row.phone }}</td>
					<td>0</td>
				</tr>
				<tr v-for="row in rows" :key="row">
					<td>{{ row.center_id }}</td>
					<td>{{ row.center_name }}</td>
					<td>{{ row.contact_person }}</td>
					<td>{{ row.phone }}</td>
					<td>{{ row.total }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script>
import Calendar from 'primevue/calendar';

export default {
	components: {
		Calendar
	},
	data() {
		return {
			dateRange: "",
			totalReports: 0,
			rows: [],
			centers: [],
			exportRows: [],
			testPrice: 0,
			doctorPrice: 0,
			agentPrice: 0,
			isSuper: false,
		}
	},
	mounted() {
	},
	methods: {
		enter() {
			let user = JSON.parse(window.localStorage.admin_access_token);
			this.isSuper = user.is_super == 'y';
		},
		downloadReport(){
			this.exportToCSV("xray-uploading", this.exportRows);
		},
		async loadLazyData() {
			let data = {};
			this.testPrice = 0;
			this.doctorPrice = 0;
			this.agentPrice = 0;
			if (this.dateRange[0] && this.dateRange[1]) {
				data.start = this.format(this.dateRange[0], "YYYY-MM-DD")+' 00:00:00';
				data.end = this.format(this.dateRange[1], "YYYY-MM-DD")+" 23:59:59";
			}

			let res = await this.post("admin/load-pft-uploads", data);
			this.rows = res.rows;
			this.centers = res.centers;

			this.exportRows = [
				["Id", "Center Name", "Contact Person", "Phone", "Agent", "Total Case"]
			];
			for(let row of this.centers){
				this.exportRows.push([
					row.id,
					row.name,
					row.contact_person,
					row.phone,
					row.agent,
					0
				]);
			}
			for(let row of this.rows){
				this.exportRows.push([
					row.center_id,
					row.center_name,
					row.contact_person,
					row.phone,
					row.agent,
					row.total
				]);
			}

			this.totalReports = 0;
			for (let r of this.rows) {
				this.totalReports += r.total;
				this.testPrice += Number(r.testPrice);
				this.doctorPrice += Number(r.doctorPrice);
				this.agentPrice += Number(r.agentPrice);
			}
		}
	},
}
</script>